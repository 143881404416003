
      const customCSSPropertiesMap = {
        "maxContentWidth": "--awsui-max-content-width-9rhwr5",
"minContentWidth": "--awsui-min-content-width-9rhwr5",
"breadcrumbsGap": "--awsui-breadcrumbs-gap-9rhwr5",
"contentGapLeft": "--awsui-content-gap-left-9rhwr5",
"contentGapRight": "--awsui-content-gap-right-9rhwr5",
"contentHeight": "--awsui-content-height-9rhwr5",
"contentLayoutDefaultHorizontalPadding": "--awsui-content-layout-default-horizontal-padding-9rhwr5",
"contentLayoutMaxContentWidth": "--awsui-content-layout-max-content-width-9rhwr5",
"contentLayoutMainGap": "--awsui-content-layout-main-gap-9rhwr5",
"defaultMaxContentWidth": "--awsui-default-max-content-width-9rhwr5",
"defaultMinContentWidth": "--awsui-default-min-content-width-9rhwr5",
"drawerSize": "--awsui-drawer-size-9rhwr5",
"footerHeight": "--awsui-footer-height-9rhwr5",
"headerGap": "--awsui-header-gap-9rhwr5",
"headerHeight": "--awsui-header-height-9rhwr5",
"layoutWidth": "--awsui-layout-width-9rhwr5",
"mainGap": "--awsui-main-gap-9rhwr5",
"mainOffsetLeft": "--awsui-main-offset-left-9rhwr5",
"mainTemplateRows": "--awsui-main-template-rows-9rhwr5",
"mobileBarHeight": "--awsui-mobile-bar-height-9rhwr5",
"notificationsHeight": "--awsui-notifications-height-9rhwr5",
"offsetTop": "--awsui-offset-top-9rhwr5",
"overlapHeight": "--awsui-overlap-height-9rhwr5",
"navigationWidth": "--awsui-navigation-width-9rhwr5",
"splitPanelReportedHeaderSize": "--awsui-split-panel-reported-header-size-9rhwr5",
"splitPanelReportedSize": "--awsui-split-panel-reported-size-9rhwr5",
"splitPanelHeight": "--awsui-split-panel-height-9rhwr5",
"splitPanelMinWidth": "--awsui-split-panel-min-width-9rhwr5",
"splitPanelMaxWidth": "--awsui-split-panel-max-width-9rhwr5",
"toolsMaxWidth": "--awsui-tools-max-width-9rhwr5",
"toolsWidth": "--awsui-tools-width-9rhwr5",
"toolsAnimationStartingOpacity": "--awsui-tools-animation-starting-opacity-9rhwr5",
"contentScrollMargin": "--awsui-content-scroll-margin-9rhwr5",
"flashbarStackDepth": "--awsui-flashbar-stack-depth-9rhwr5",
"flashbarStackIndex": "--awsui-flashbar-stack-index-9rhwr5",
"flashbarStickyBottomMargin": "--awsui-flashbar-sticky-bottom-margin-9rhwr5",
"stackedNotificationsBottomMargin": "--awsui-stacked-notifications-bottom-margin-9rhwr5",
"stackedNotificationsDefaultBottomMargin": "--awsui-stacked-notifications-default-bottom-margin-9rhwr5",
"dropdownDefaultMaxWidth": "--awsui-dropdown-default-max-width-9rhwr5",
"spinnerRotatorFrom": "--awsui-spinner-rotator-from-9rhwr5",
"spinnerRotatorTo": "--awsui-spinner-rotator-to-9rhwr5",
"spinnerLineLeftFrom": "--awsui-spinner-line-left-from-9rhwr5",
"spinnerLineLeftTo": "--awsui-spinner-line-left-to-9rhwr5",
"spinnerLineRightFrom": "--awsui-spinner-line-right-from-9rhwr5",
"spinnerLineRightTo": "--awsui-spinner-line-right-to-9rhwr5",
"sliderLabelCount": "--awsui-slider-label-count-9rhwr5",
"sliderTickCount": "--awsui-slider-tick-count-9rhwr5",
"sliderReferenceColumn": "--awsui-slider-reference-column-9rhwr5",
"sliderNextReferenceColumn": "--awsui-slider-next-reference-column-9rhwr5",
"sliderMaxStart": "--awsui-slider-max-start-9rhwr5",
"sliderMinEnd": "--awsui-slider-min-end-9rhwr5",
"sliderRangeInlineSize": "--awsui-slider-range-inline-size-9rhwr5",
"sliderTooltipPosition": "--awsui-slider-tooltip-position-9rhwr5",
"togglesLeftWidth": "--awsui-toggles-left-width-9rhwr5",
"togglesRightWidth": "--awsui-toggles-right-width-9rhwr5",
      };
      export default customCSSPropertiesMap;
    